@import "./mixins";

:root {
  --base-font-size: 20px;
  --color: 121, 35.5%;
  --color-l: 30.4%;
  --light-color: 35, 36.4%;
  --light-color-l: 70.4%;

  --white-color: hsl(0, 0%, 100%);
  --primary-color: hsl(var(--color), var(--color-l)); // #326933
  --primary-highlight: hsl(var(--color), calc(var(--color-l) + 15%));
  --background-color: hsl(var(--light-color), var(--light-color-l)); // #cfb898
}

html {
  font-size: var(--base-font-size);
  font-family: Montserrat, sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1.8rem;

  @include for-desktop-down {
    font-size: calc(var(--base-font-size) * 0.9);
  }

  @include for-tablet-landscape-down {
    font-size: calc(var(--base-font-size) * 0.8);
  }

  @include for-tablet-portrait-down {
    font-size: calc(var(--base-font-size) * 0.7);
  }

  @include for-phone-down {
    font-size: calc(var(--base-font-size) * 0.7);
  }

  @include for-mini-phone-down {
    font-size: calc(var(--base-font-size) * 0.8);
  }
}

h1 {
  font-size: 3rem;
  line-height: 5rem;

  @include for-phone-down() {
    font-size: 2.8rem;
  }

  @include for-mini-phone-down {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

h2 {
  font-size: 2.4rem;
  line-height: 4rem;
}
